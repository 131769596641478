<ion-app>
  <ion-header>
    <ion-toolbar [ngClass]="{ hidden: this.router.url.includes('login') }">
      <div class="flex-row align-items-center gap-l padding-left padding-right">
        <div class="flex-row gap align-items-flex-end flex-1">
          <ion-img
            class="doka-logo"
            *ngIf="!environment.production"
            src="../assets/doka-logo-stage.svg"
          >
          </ion-img>
          <ion-img *ngIf="environment.production" src="../assets/doka-logo.svg">
          </ion-img>
          <div class="delivery-app-mode flex-row align-items-center">
            <p>EmIoT</p>
          </div>
        </div>
        <div class="langSelect" (click)="onOpenLanguageSelect()">
          <p>{{ dokaTranslation.selectedLanguage }}</p>
          <doka-icon name="keyboard_arrow_down"></doka-icon>
        </div>
        <div
          class="userBadge"
          [ngClass]="{ hidden: !dokaIdentityLogin.user.id }"
          #userBadge
          (click)="isUserMenuOpen = !isUserMenuOpen"
        >
          <doka-avatar
            [src]="dokaIdentityLogin.user.profilePictureUrl"
            [initials]="
              dokaIdentityLogin.user.firstname[0] +
              dokaIdentityLogin.user.lastname[0]
            "
          ></doka-avatar>
          <div class="flex-column">
            <div class="flex-row text-base bold">
              <p>
                {{ dokaIdentityLogin.user.firstname }}
                {{ dokaIdentityLogin.user.lastname }}
              </p>
            </div>
            <div class="flex-row text-base gap-s bold">
              <p>{{ "Header.SelectedBranch" | i18nextEager }}</p>
              <p *ngIf="branchName">{{ branchName }}</p>
            </div>
          </div>
          <doka-icon
            [name]="
              isUserMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
            "
          ></doka-icon>
        </div>
      </div>
    </ion-toolbar>
    <div class="userMenu" [ngClass]="{ userMenuOpen: isUserMenuOpen }">
      <div class="menuItem" (click)="redirectToSelectBranch()">
        <doka-icon name="settings"></doka-icon>
        <p style="text-wrap: wrap">
          {{ "UserMenu.SelectBranch" | i18nextEager }}
        </p>
      </div>
      <div class="menuItem" (click)="onLogout()">
        <doka-icon name="logout"></doka-icon>
        <p style="text-wrap: wrap">
          {{ "UserMenu.Logout" | i18nextEager }}
        </p>
      </div>
    </div>
  </ion-header>

  <div class="contentSpacer">
    <ion-router-outlet
      (click)="isUserMenuOpen = false"
      id="main"
      class="routerOutlet"
    ></ion-router-outlet>
  </div>
</ion-app>

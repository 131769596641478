import { CommonModule, DatePipe } from "@angular/common";
import { DokaCommonModule, WeightPipe } from "@doka/common";

import { FormsModule } from "@angular/forms";
import { I18NextModule } from "angular-i18next";
import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
    CommonModule,
    DokaCommonModule,
    FormsModule,
    IonicModule,
    I18NextModule
  ],
  providers: [
    WeightPipe,
    DatePipe,
  ],
  exports: [
    CommonModule,
    DokaCommonModule,
    FormsModule,
    IonicModule,
    I18NextModule
  ]
})
export class CoreModule { }

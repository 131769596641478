import { DokaIdentityLoginService } from "@doka/identity-login";
import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { authCodeFlowConfig } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public authenticationHeaders = {
    Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
    "accept-version": "1.0",
  };


  constructor(private dokaIdentityLoginService: DokaIdentityLoginService, private navCrtl: NavController, private oauthService: OAuthService, private router: Router) {
    this.dokaIdentityLoginService.configureOAuth2(authCodeFlowConfig);

    // Redirect to initially requested page
    this.oauthService.events.subscribe((event) => {
      if (event.type === "token_received") {
        let redirectUrl = sessionStorage.getItem('redirectUrl');
        try {
          if (this.oauthService.state) {
            // Assuming state contains a URL-encoded redirect path
            redirectUrl = decodeURIComponent(this.oauthService.state);
          }
        } catch (e) {
          console.error("Error decoding state parameter for redirect:", e);
        }
        sessionStorage.removeItem('redirectUrl'); // Clean up
        window.location.href = redirectUrl!; // Redirect to the originally intended URL
        // Navigate to the decoded URL or home if none was provided/found
        /* this.router.navigateByUrl(redirectUrl.substring(redirectUrl.lastIndexOf("/"))).catch((err) => {
          console.error("Failed to navigate to redirectUrl:", redirectUrl, err);
          // Fallback navigation, e.g., to home
          this.router.navigateByUrl("/gallery").catch((err) => {
            console.error("Failed to navigate to fallback URL:", err);
          });
        }); */
      }
    });
  }

  /* Loggin out, means navigating to login Page and deleting token adn userid in App-Config */
  logout() {
    this.dokaIdentityLoginService.logout();
    this.navCrtl.navigateBack("/login");
    localStorage.clear();
  }

  get isOAuthLoggedIn() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken() && this.oauthService.getAccessTokenExpiration() > (new Date()).getTime();
  }

  /**
   * @brief Starts Login Flow
   * @param url redirectUrl after successful login
   */
  public oauthLogin(url?: string) {
    this.oauthService.initLoginFlow(url);
  }

  public async getUser(): Promise<string> {
    return `${this.dokaIdentityLoginService.user.firstname} ${this.dokaIdentityLoginService.user.lastname}`;
  }

  public getUserId(): string {
    return this.dokaIdentityLoginService.user.id ?? '';
  }
}

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './modules/core.module';
import { I18N_PROVIDERS } from './i18n';
import { I18NextModule } from 'angular-i18next';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { MenuController } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { RouteReuseStrategy } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    CoreModule,
    I18NextModule.forRoot(),
    OAuthModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    PowerBIEmbedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    { provide: OAuthStorage, useValue: localStorage },
    I18N_PROVIDERS,
    MenuController,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

export class EmitEvent {
  constructor(public name: any, public value?: any) {}
}

@Injectable({
  providedIn: "root",
})
export class EventBusService {
  private subject$ = new Subject<any>();

  on(event: any, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: EmitEvent) => e.name === event),
        map((e: EmitEvent) => e.value)
      )
      .subscribe(action);
  }

  emit(event: EmitEvent) {
    this.subject$.next(event);
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DokaIdentityLoginService } from '@doka/identity-login';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private dokaIdentityLogin: DokaIdentityLoginService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.dokaIdentityLogin.getAccessToken();
    const isInternalUrl =
      request.url.startsWith(environment.yardApiUrl) ||
      request.url.startsWith(environment.powerbi);

    const interceptedRequest =
      token && isInternalUrl
        ? request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.dokaIdentityLogin.getAccessToken()}`,
            },
          })
        : request;

    return next.handle(interceptedRequest);
  }
}

import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";

import { AuthenticationService } from "./../services/authentication.service";
import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard {
  path: import("@angular/router").ActivatedRouteSnapshot[] | null = null;
  route: import("@angular/router").ActivatedRouteSnapshot | null = null;

  constructor(
    private navCtrl: NavController,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn = this.authenticationService.isOAuthLoggedIn;
    if (isLoggedIn) {
      return true;
    } else {
      this.navCtrl.navigateBack("/login");
      return false;
    }
  }
}

import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import {
  I18NEXT_SERVICE,
  I18NextLoadResult,
  I18NextModule,
  ITranslationService,
  defaultInterpolationFormat,
} from 'angular-i18next';

import ChainedBackend from 'i18next-chained-backend';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import resourcesToBackend from 'i18next-resources-to-backend';
import translationDE from './locals/de/translation.json';
import translationEN from './locals/en/translation.json';

var availableLanguages: string[] = ['en'];

const bundledResources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
};

const supportedPowerBiTranslations = ['en', 'de'];

const getAvailableLanguages = async (http: HttpClient): Promise<string[]> => {
  let res: string[] = [];
  if (environment.translationsApiBaseUrl) {
    try {
      res = await lastValueFrom<string[]>(http.get<string[]>(`${environment.translationsApiBaseUrl}/api/translations/${environment.identityServerClientId}`,));
    } catch (error) {
      console.warn(error);
      return availableLanguages;
    }
  }

  if (res && res.length > 0) {
    return res;
  } else {
    return availableLanguages;
  }
};

const getI18nextOptions = (http: HttpClient) => {
  return {
    fallbackLng: 'en',
    fallbackOnNull: false,
    fallBackOnEmpty: false,
    keySeparator: '.',
    returnEmptyString: false,
    supportedLngs: availableLanguages,
    interpolation: {
      format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
    },
    backend: {
      backends: [HttpBackend, resourcesToBackend(bundledResources)],
      backendOptions: [
        {
          loadPath: `${environment.translationsApiBaseUrl}/api/translations/${environment.identityServerClientId}/{{lng}}/translation.json`,
          async request(_, url, __, callback) {
            if (environment.stage === 'local') {
              // Always use bundled translation files for Local env
              // Submitting empty payload will cause i18n to use the next backend (bundled translation files)
              callback(undefined, { status: 200, data: {} });
            } else {
              // Load translation files from API
              http.get(url).subscribe({
                next(data) {
                  callback(undefined, { status: 200, data });
                },
                error(error) {
                  callback(error, { status: 500, data: {} });
                },
              });
            }
          },
        } as HttpBackendOptions,
      ],
    },
  };
};

const appInit = (i18next: ITranslationService, http: HttpClient) => {
  return async () => {
    try {
      availableLanguages = await getAvailableLanguages(http);
    } catch (error) {
      console.warn(
        'could not get availableTranslations from server, using locals instead'
      );
    }
    const promise: Promise<I18NextLoadResult> = i18next
      .use(ChainedBackend)
      .init(getI18nextOptions(http));
    return promise;
  };
};

const localeIdFactory = (i18next: ITranslationService) => {
  return i18next.language;
};

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE, HttpClient],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

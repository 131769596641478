import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DokaCommonService, SelectEntry } from '@doka/common';

import { AuthenticationService } from './services/authentication.service';
import { Branch } from './models/branch.model';
import { DokaI18NextService } from '@doka/i18next';
import { DokaIdentityLoginService } from '@doka/identity-login';
import { DokaSelectService } from '@doka/common';
import { EventBusActions } from './models/event-bus-actions';
import { EventBusService } from './services/event-bus.service';
import { MenuController } from '@ionic/angular';
import { MenuService } from './services/menu.service';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  branchName: string = '';
  eventBusSubscription: Subscription = new Subscription();

  constructor(
    protected dokaIdentityLogin: DokaIdentityLoginService,
    protected dokaTranslation: DokaI18NextService,
    protected menuService: MenuService,
    protected router: Router,
    private authenticationService: AuthenticationService,
    private dokaCommonService: DokaCommonService,
    private menuController: MenuController,
    private platform: Platform,
    private selectService: DokaSelectService,
    private viewContainerRef: ViewContainerRef,
    private eventBusService: EventBusService,
    private storageService: StorageService
  ) {
    this.platform.ready().then(() => {
      this.initializeApp();
    });
  }

  ngOnInit() {
    this.dokaTranslation.changeLanguage(this.storageService.getSelectedLanguage() ?? 'en');
    this.eventBusSubscription.add(
      this.eventBusService.on(
        EventBusActions.selectedBranchDetailsReceived,
        (branch: Branch) => {
          this.branchName = branch.name;
        }
      )
    );
  }

  redirectToSelectBranch() {
    this.router.navigateByUrl('select-branch');
  }

  protected environment = environment;
  protected isUserMenuOpen: boolean = false;

  protected onCloseMenu(): void {
    this.menuController.close();
  }

  protected onLogout(): void {
    this.authenticationService.logout();
  }

  protected async onOpenLanguageSelect(): Promise<void> {
    let selectEntries: SelectEntry[] = [];
    let availableTranslations: string[] = [];
    const supportedPowerBiTranslations = ['en', 'de'];

    if (
      this.dokaTranslation.availableTranslations &&
      this.dokaTranslation.availableTranslations?.length > 0
    ) {
      availableTranslations = this.dokaTranslation.availableTranslations.filter(
        (lang) => supportedPowerBiTranslations.includes(lang)
      );

      for (let translation of availableTranslations) {
        selectEntries.push({
          label: this.dokaTranslation.instant(translation.toUpperCase()),
          value: translation,
        });
      }
    }

    let selectedEntry = selectEntries.find(
      (entry) => entry.value === this.dokaTranslation.selectedLanguage
    );

    const select = await this.selectService.create(
      selectEntries,
      selectedEntry ? [selectedEntry] : [],
      false,
      this.dokaTranslation.instant('Language')
    );

    let res = await select.onDidDismiss();
    if (res && res.length > 0) {
      if (res[0].value !== this.dokaTranslation.selectedLanguage) {
        this.dokaTranslation.changeLanguage(res[0].value);
        this.storageService.setSelectedLanguage(res[0].value);
        document.location.reload();
      }
    }
  }

  protected onOpenMenu(): void {
    this.menuController.open();
  }

  private async initializeApp(): Promise<void> {
    await this.dokaTranslation.init(
      environment.translationsApiBaseUrl,
      environment.identityServerClientId
    );
    this.dokaCommonService.init(this.viewContainerRef);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  
  setSelectedLanguage(selectedLanguage: string) {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }

  getSelectedLanguage() {
    return localStorage.getItem('selectedLanguage');
  }
}
